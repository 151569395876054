import React from "react";
import "../styles/styles.css";
import { useMediaQuery } from "react-responsive";

export default function Box(props) {
    const { name, abbreviation, elementName, elements, extraClass } = props;

    const isSmallMobile = useMediaQuery({ query: "(max-width: 410px)"});

    return (
        <div className={`small-box${extraClass}`}>
            <p className="green-p-box">{abbreviation}</p>
            <h2 className="light-title element-info-h2">{name}</h2>
            {!isSmallMobile && <p className="light-grey element-info-p">{`${elements} ${elementName}`}</p>}
        </div>
    )
}