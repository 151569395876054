import { createContext, useReducer } from "react"

const initialState = {
    isAccountActivated: null,
    isTrialUsed: null,
    remainingDays: null
};

export const SubscriptionContext = createContext();

const subscriptionReducer = (state, action) => {
    switch(action.type) {
        case 'SET_STATUS':
            return {
                ...state,
                isAccountActivated: action.payload.account_is_activated,
                isTrialUsed: action.payload.trial_was_used
            }

        case 'SET_REMAINING_DAYS':
            return {
                ...state,
                remainingDays: Number(action.payload.days_left)
            }

        default:
            return state;
    }
} 

export const SubscriptionContextProvider = ({ children }) => {
    const [subscriptionDetails, dispatch] = useReducer(subscriptionReducer, initialState);

    return (
        <SubscriptionContext.Provider value={{ ...subscriptionDetails, dispatch }}>
            {children}
        </SubscriptionContext.Provider>
    )
}