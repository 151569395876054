import { createContext, useReducer } from "react"

export const LoaderContext = createContext();

const loaderReducer = (state, action) => {
    switch(action.type) {
        case 'LOAD':
            return true;

        case 'NO_LOAD':
            return false;

        default:
            return state;
    }
} 

export const LoaderContextProvider = ({ children }) => {
    const [isLoading, dispatch] = useReducer(loaderReducer, false);

    return (
        <LoaderContext.Provider value={{ isLoading, dispatch }}>
            {children}
        </LoaderContext.Provider>
    )
}