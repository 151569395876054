import { useMemo, useEffect, useState } from "react";
import Box from "../Box";
import { Link } from "react-router-dom";
import RevealOnScroll from "../../animations/RevealOnScroll";
import { useMediaQuery } from "react-responsive";
import api from "../../api";
import useLoaderContext from "../../hooks/useLoaderContext";
import useMajorsContext from "../../hooks/useMajorsContext";
import useErrorContext from "../../hooks/useErrorContext";

export default function Majors({ setShowError }) {
    const show = useMemo(() => ["Show All", "+"], []);
    const hide = useMemo(() => ["Show Less", "-"], []);
    
    const [majorsElement, setMajorsElement] = useState([]);
    const [showAllButton, setShowAllButton] = useState(show)
    const [showAll, setShowAll] = useState();
    
    const isMedium = useMediaQuery({ query: '(max-width: 768px)' });
    
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { majors, dispatch:majorsDispatch } = useMajorsContext();

    // Fetch the majors if not already fetched
    useEffect(() => {
        const fetchMajors = async () => {
            try {
                loadingDispatch({ type: 'LOAD' });
                const res = await api.get('/getMajors/');
                const { data } = res;
                
                majorsDispatch({ type: 'SET_MAJORS', payload: data });
                loadingDispatch({ type: 'NO_LOAD' });
            } catch (error) {
                errorDispatch({ type: 'ERROR' });
                setShowError(true);
            }
        }

        if (!majors) fetchMajors();
    }, [loadingDispatch, majors, majorsDispatch, setShowError, errorDispatch]);

    useEffect(() => {
        setShowAll(false);
    }, []);

    // Handle the number of courses showing
    useEffect(() => {
        if (majors) {
            let majorsArr;
    
            if (!showAll) {
                majorsArr = [...majors.slice(0,8)];
                setShowAllButton(show);
            } else {
                majorsArr = [...majors]
                setShowAllButton(hide);
            }
    
            setMajorsElement(majorsArr.map((major, id) => {
                return (
                    <RevealOnScroll key={id} once={true}>
                        <Link to="/courses" state={{ major: major, majorId: major.id }}>
                            <Box 
                                name={major.name}
                                abbreviation={major.code}
                                elementName={`Course${major.num_courses !== 1 ? "s" : ""}`}
                                elements={major.num_courses}
                                extraClass=""
                            />
                        </Link>
                    </RevealOnScroll>
                )
            }));
        }
    }, [showAll, majors, show, hide]);

    function handleClick(event) {
        event.preventDefault();
        setShowAll(prev => !prev);
    }
    

    return (
        <div id="majors--container">
            <div id="majors-elements">
                {majorsElement}
                {isMedium && 
                <button className="small-button" onClick={handleClick}>
                    <p>{showAllButton[0]}</p>
                    <p id="show-all-p">{showAllButton[1]}</p>
                </button>}
            </div>
            {!isMedium && 
            <button className="small-button" onClick={handleClick}>
                <p>{showAllButton[0]}</p>
                <p id="show-all-p">{showAllButton[1]}</p>
            </button>}
        </div>
    )
}