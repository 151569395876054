import { Link } from "react-router-dom";

export default function BackArrow({ to, additionalStyle = {}, onClick = () => {}, additionalClass = "", state = {} }) {
    return (
        <Link 
            to={to} 
            className={`back-button${additionalClass !== "" ? " " : ""}${additionalClass}`} 
            onClick={onClick} 
            style={{ ...additionalStyle }}
            state={{ ...state }}
        >
            <span className="material-symbols-outlined back-arrow">arrow_back_ios</span>
        </Link>
    )
}