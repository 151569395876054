import mappings from "../../data/daysMappings";
import useOverflowContext from "../../hooks/useOverflowContext";
import useBookingContext from "../../hooks/useBookingsContext";
import useUserInfoContext from "../../hooks/useUserInfoContext";
import useLoaderContext from "../../hooks/useLoaderContext";
import usePendingsContext from "../../hooks/usePendingsContext";
import apiPrivate from "../../apiPrivate.js";

export default function BookingNotification(
    { 
        setBookingNotification, 
        tutor, student, course, day, hour, hourlyRate=0, bookingId, courseId, userId,
        isBooking, setDate, isPending, isTutorBookings, setSuccessNotification,
        setMessage, isCancel
    }
) {
    const { days, months } = mappings;
    
    const { dispatch:overflowDispatch } = useOverflowContext();
    const { dispatch:bookingDispatch } = useBookingContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:pendingDispatch } = usePendingsContext();
    const { isTutor } = useUserInfoContext();

    const bookingMessage = `You are booking a session with ${tutor || student}.`;
    const cancelMessage = `Are you sure you want to cancel your session with ${tutor || student}?`;

    const newDay = new Date(day);
    const fullDay = `${days[newDay.getDay()]} ${newDay.getDate()} ${months[newDay.getMonth()]} ${newDay.getFullYear()}`;

    // Book a new slot
    const bookSlot = async () => {
        try {
            loadingDispatch({ type: 'LOAD' });
            await apiPrivate.put('/user/bookSlot',{
                booking_id: Number(bookingId),
                course_id: Number(courseId)
            })
            loadingDispatch({ type: 'NO_LOAD' });
            setDate(null);

            setSuccessNotification(true);
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            setMessage({
                success: null,
                error: error.response.data.detail ? error.response.data.detail : error.response.data
            });
        }
    }
    
    // Cancel the booking
    const cancelBooking = async () => {
        try {
            const url = `/${isTutorBookings ? "tutor" : "user"}/cancelBooking`;
            
            await apiPrivate.delete(url, {
                params: { 
                    booking_id: bookingId 
                }
            });
            
            if (isPending) {
                pendingDispatch({ type: 'DELETE_PENDING', payload: { bookingId, isTutor: isTutorBookings } });
            } else {
                bookingDispatch({ type: "DELETE_BOOKING", payload: { bookingId, day, isTutorBookings } });
            }

            setMessage({
                success: "Booking canceled successfully!",
                error: null
            });
        } catch (error) {
            setMessage({
                success: null,
                error: error.response.data
            });
        }
    }

    // Accept pending request
    const acceptPending = async () => {
        try {
            loadingDispatch({ type: 'LOAD' });
            await apiPrivate.get(`/tutor/confirmBooking`, {
                params: { 
                    booking_id: bookingId,
                    user_id: userId,
                    course_id: courseId
                }
            })
            
            loadingDispatch({ type: 'NO_LOAD' });
            setDate(null);
            setMessage({
                success: "Booking accepted successfully!",
                error: null
            });
            pendingDispatch({ type: 'DELETE_PENDING', payload: { bookingId, isTutor: isTutorBookings } });
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            setMessage({
                success: null,
                error: error.response.data.details ? error.response.data.details : error.response.data
            });
        }
    }

    function handleConfirm(isConfirmed) {
        setBookingNotification(prev => ({
                ...prev,
                show: isConfirmed
            })
        )

        if (isConfirmed) {
            // Update the database with the info needed
            if (isBooking) bookSlot();
            else if (isPending && isTutorBookings && !isCancel) acceptPending();
            else cancelBooking();

            // Reload the booking page
            overflowDispatch({ type: 'SET_FREEZE', payload: false });
            setBookingNotification(false);
        } else {
            overflowDispatch({ type: 'SET_FREEZE', payload: false });
        }
    }

    return (
        <div className="booking-notification--bg">
            <div id="booking-notification--container">
                <h3>{isBooking ? bookingMessage : cancelMessage}</h3>
                <p>
                    <span className="booking-notification--subtitle">Course: </span>
                    <span>{course}</span>
                </p>
                <p>
                    <span className="booking-notification--subtitle">Date and time: </span>
                    <span>{`${fullDay} at ${hour}:00`}</span>
                </p>
                {isBooking && <p>
                    <span className="booking-notification--subtitle">Total: </span>
                    <span>{`$ ${hourlyRate}`}</span>
                </p>}

                <div id="booking-notif-buttons">
                    <button className="small-button" onClick={() => handleConfirm(true)}>Confirm</button>
                    <button className="small-button" id="red-button" onClick={() => handleConfirm(false)}>Cancel</button>
                </div>

                {isBooking &&
                <p className="booking-notification-note">
                    <span>
                        {"Upon confirming this booking, an email will be sent to the tutor, and their approval will be required."}
                    </span>
                </p>}
                
                {!isBooking && !isPending &&
                    <p className="booking-notification-note">{`Upon confirmation, an email will be sent to your ${isTutor ? "student" : "tutor"} notifying them of the cancellation.`}</p>
                }

                {isPending && isTutor &&
                    <p className="booking-notification-note">Upon confirmation, an email will be sent to your student notifying them of the approval.</p>
                }
            
            </div>
        </div>
    )
}