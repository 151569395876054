import { createContext, useReducer } from "react"

export const CoursesContext = createContext();

const coursesReducer = (state, action) => {
    switch(action.type) {
        case 'SET_COURSES':
            return action.payload;

        default: 
            return state;
    }
} 

export const CoursesContextProvider = ({ children }) => {
    const [courses, dispatch] = useReducer(coursesReducer, null);

    return (
        <CoursesContext.Provider value={{ courses, dispatch }}>
            {children}
        </CoursesContext.Provider>
    )
}