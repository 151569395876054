import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { LoaderContextProvider } from './context/LoaderContext';
import { OverflowContextProvider } from './context/OverflowContext';
import { BookingsContextProvider } from './context/BookingsContext';
import { UserInfoContextProvider } from './context/UserInfoContext';
import { PendingsContextProvider } from './context/PendingsContext';
import ApiCalls from './apiCalls/ApiCalls';
import { MajorsContextProvider } from './context/MajorsContext';
import { LanguagesContextProvider } from './context/LanguagesContext';
import { CoursesContextProvider } from './context/CoursesContext';
import { ErrorContextProvider } from './context/ErrorContext';
import { SubscriptionContextProvider } from './context/SubscriptionContext';
import NetworkStatus from './components/NetworkStatus';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <UserInfoContextProvider>
      <AuthContextProvider>  
        <LoaderContextProvider>
          <ErrorContextProvider>
            <OverflowContextProvider>
              <BookingsContextProvider>
                  <PendingsContextProvider>
                    <MajorsContextProvider>
                      <LanguagesContextProvider>
                        <CoursesContextProvider>
                          <SubscriptionContextProvider>
                              <App />
                              <ApiCalls />
                              <NetworkStatus />
                          </SubscriptionContextProvider>
                        </CoursesContextProvider>
                      </LanguagesContextProvider>
                    </MajorsContextProvider>
                  </PendingsContextProvider>
              </BookingsContextProvider>
            </OverflowContextProvider>
          </ErrorContextProvider>
        </LoaderContextProvider>  
      </AuthContextProvider>
    </UserInfoContextProvider>
  </React.StrictMode>
);

