import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "../../styles/tutor-courses.css";
import { StarRating } from "../StarRating";
import { TitleBack } from "../TitleBack";

export default function TutorCourses() {
    const { tutorInfo, tutor } = useLocation().state;
    const [search, setSearch] = useState("");
    const [courses, setCourses] = useState(tutorInfo.taught_courses);
    const [coursesElement, setCoursesElement] = useState([]);

    useEffect(() => {
        setCoursesElement(() => {
            if (courses.length === 0) {
                return [];
            }

            return courses.map((course, id) => {
                return (
                    <div id="tutor-course-box" key={id}>
                     <p className="green-p-box medium-size">{`${course.major.code}${course.code}`}</p>
                        <div className="course-title">
                            <p>{course.name}</p>
                        </div>
                    
                        <Link 
                            className="small-button margin-less" 
                            to="/tutors/booking" 
                            state={
                                {
                                    tutorId: tutorInfo.id,
                                    tutor,
                                    hourlyRate: tutorInfo.hourlyRate,
                                    tutorInfo,
                                    course
                                }
                            }
                        >Book</Link>
                    </div>
                )
            })   
        })
    }, [courses, tutor, tutorInfo])

    useEffect(() => {
        setCourses(tutorInfo.taught_courses.filter((course) => course.name.toLowerCase().includes(search.toLowerCase())));
    }, [search, tutorInfo.taught_courses])

    // Handle the search
    function handleSearch(event) {
        setSearch(event.target.value);
    }

    return (
        <div id="tutor-courses--container">   
            <TitleBack 
                title={tutor}
                to="/tutors"
            />         

            <div id="tutor-courses--box">
                <div id="tutor-info">
                    {tutorInfo.user.profile_picture ?
                        <img 
                            src={tutorInfo.user.profile_picture}
                            alt="Tutor Profile"
                            className="profile-picture profile-picture-img--tutor-courses"
                            style={{ width: "160px", height: "160px" }}
                            loading="lazy"
                        />
                        :
                        <span className="material-symbols-outlined" id="tutor-profile">account_circle</span>
                    }   
                    
                    <StarRating 
                        tutorId={tutorInfo.id}
                    />

                    <div className="tutor-info--tutorcourses">
                        <div id="email--container" className="tutor-courses--titles">
                            <p className="very-small-title">EMAIL:</p>
                            <a href={`mailto:${tutorInfo.user.email}`} className="email-link">{tutorInfo.user.email}</a>
                        </div>

                        <div id="description--container" className="tutor-courses--titles">
                            <p className="very-small-title">DESCRIPTION:</p>
                            <p className="light-grey regular">{tutorInfo.description}</p>
                            {/* <p className="light-grey regular">{tutorInfo.description}</p> */}
                        </div>

                        <div id="language--container" className="tutor-courses--titles">
                            <p className="very-small-title">SPEAKS:</p>
                            <p className="light-grey regular">{"French"}</p>
                        </div>

                        <div id="hourly-rate--container" className="tutor-courses--titles">
                            <p className="very-small-title">HOURLY RATE FROM:</p>
                            <div className="flex-row light-grey">
                                <p className="regular">USD&nbsp;</p>
                                <p className="bold">{tutorInfo.rate}</p>
                            </div>
                        </div>
                    </div>

                </div>
                    <div id="tutor-courses--content">
                        <div className="search--container" id="tutor-courses--search">
                            <div id="search-bar">
                                <input type="text" name="searchEntry" placeholder="Search Courses" onChange={handleSearch} value={search} />
                                <span className="material-symbols-outlined" id="search-icon">search</span>
                            </div>
                        </div>

                        {coursesElement.length === 0 ? <p className="small-grey margin-top">No course was found.</p> : <div id="tutor-courses">{coursesElement}</div>}
                    </div>
            </div>
        </div>
    )
}