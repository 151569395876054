export default function Notification({ title, content, handleConfirm, handleCancel, doneButton, isWarning }) {
    return (
        <div className="notification--whole-page whole-page--blurred">
            <div className="notification--container">
                {isWarning ?
                <lord-icon
                    src="https://cdn.lordicon.com/ygvjgdmk.json"
                    trigger="hover"
                    colors="primary:#d94d4d"
                    style={{ width: "100px", height: "100px" }}
                ></lord-icon>
                :
                <lord-icon
                    src="https://cdn.lordicon.com/oqdmuxru.json"
                    trigger="hover"
                    colors="primary:#00ac97"
                    style={{ width: "100px", height: "100px" }}
                ></lord-icon>}
                <h1>{title}</h1>
                <div className="notification-container--p">
                    {content}
                </div>

                {doneButton ? 
                <button className="confirmation-button" onClick={handleConfirm}>Done</button>
                :
                <div className="notification-buttons--container">
                    <button className="confirmation-button" onClick={handleConfirm}>Confirm</button>
                    <button className="cancelation-button" onClick={handleCancel}>Cancel</button>
                </div>
                }
            </div>
        </div>
    )
}