import { createContext, useReducer } from "react"

export const UserInfoContext = createContext();

const userInfoReducer = (state, action) => {
    switch(action.type) {
        case 'INIT_USERINFO':
            if (state && !state.user) {
                return {
                    user: action.payload,
                    isTutor: action.payload.user ? action.payload.user.is_tutor : action.payload.is_tutor
                }
            }
            return state;

        case 'FORCE_USERINFO':
            return {
                user: action.payload,
                isTutor: action.payload.user ? action.payload.user.is_tutor : action.payload.is_tutor
            }

        case 'EDIT_USERINFO':
            const { user, isTutor } = state;

            if (isTutor) {
                const newUser = {
                    ...user,
                    user: {
                        ...user.user,
                        ...action.payload
                    }
                }

                return {
                    ...state,
                    user: newUser
                }
            } else {
                return {
                    ...state,
                    user: {
                        ...user,
                        ...action.payload
                    }
                }
            }

        case 'EDIT_ADVANCED_USERINFO':
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }


        case 'RESET_USERINFO':
            return {
                user: null,
                isTutor: null
            }

        default:
            return state;
    }
} 

export const UserInfoContextProvider = ({ children }) => {
    const [userInfo, dispatch] = useReducer(userInfoReducer, {
        user: null,
        isTutor: null
    });

    return (
        <UserInfoContext.Provider value={{ ...userInfo, dispatch }}>
            {children}
        </UserInfoContext.Provider>
    )
}