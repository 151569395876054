import { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "../../styles/settings.css";
import "../../styles/booking.css"
import { Link, useLocation } from "react-router-dom";
import routingMappings from "../../data/routingMappings";
import { useMediaQuery } from "react-responsive";
import useLogout from "../../hooks/useLogout";
import BackArrow from "../BackArrow";
import validators from "../../helpers/validators";
import useUserInfoContext from "../../hooks/useUserInfoContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastHelpers from "../../helpers/toastHelper";
import ChooseFileButton from "../ChooseFileButton";
import useLoaderContext from "../../hooks/useLoaderContext.js";
import imageCompression from "browser-image-compression";
import apiPrivate from "../../apiPrivate.js";

export default function GeneralSettings({ setShowSettings, active }) {
    const { toastError, toastSuccess } = toastHelpers;
    const errorMessage = "Something went wrong. Please try again.";
    const successMessage = "Changes saved successfully!";

    const { pathname:currentRoute } = useLocation();

    const [formData, setFormData] = useState({
        first_name : "",
        last_name: "",
        old_password: "",
        password: "",
        confirm_password: "",
        profile: {
            phone_number: ""
        }
    });
    const [originalForm, setOriginalForm] = useState(null);

    const [isClosed, setIsClosed] = useState(false);
    
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

    const { user:allUserInfo, isTutor, dispatch:userInfoDispatch } = useUserInfoContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const userInfo = isTutor ? allUserInfo.user : allUserInfo;

    const [error, setError] = useState(null);

    const { isValidPassword, isValidNumber } = validators;
    const { logout } = useLogout();

    const [fileUpload, setFileUpload] = useState(null);
    const [fileURL, setFileURL] = useState("");
    const [originalPicture, setOriginalPicture] = useState(null);
    const fileRef = useRef(null);

    useEffect(() => {
        if (userInfo) {
            const ogInfo = {
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                profile: {
                    phone_number: userInfo.profile.phone_number || ""
                },
                old_password: "",
                password: "",
                confirm_password: "",
            };

            setOriginalForm({ ...ogInfo });
            setFormData({ ...ogInfo });

            // fix the profile picture
            setOriginalPicture(userInfo.profile_picture);
        }
    }, [userInfo])

    // Change name
    const changeName = async () => {
        const firstName =  originalForm.first_name !== formData.first_name ? `first_name=${formData.first_name}` : "";

        const isLastChanged = originalForm.last_name !== formData.last_name
        let lastName = isLastChanged ? "&" : "";
        lastName += isLastChanged ? `last_name=${formData.last_name}` : "";

        try {
            await apiPrivate.put(`/user/changeName?${firstName}${lastName}`, {});

            const updatedInfo = {
                first_name: formData.first_name,
                last_name: formData.last_name
            };

            userInfoDispatch({ type: 'EDIT_USERINFO', payload: updatedInfo });
            setOriginalForm(updatedInfo);
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    // Change password
    const changePassword = async () => {
        try {
            await apiPrivate.put(`/user/changePassword`, {
                "old_password": formData.old_password,
                "new_password": formData.password,
                "confirm_password": formData.confirm_password                
            });

            logout();
            setShowSettings(false);
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    // Change phone number
    const changePhoneNumber = async () => {
        try {
            await apiPrivate.put(`/user/changePhoneNumber`, {
                "phone_number": formData.profile.phone_number          
            });

            const updatedInfo = {
                ...userInfo.profile,
                phone_number: formData.profile.phone_number
            };

            userInfoDispatch({ type: 'EDIT_USERINFO', payload: { profile: { ...updatedInfo } } });
            setOriginalForm(updatedInfo);
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    // Change the profile picture
    const uploadProfilePicture = async () => {
        try {
            const formDataFile = new FormData();
            formDataFile.append('file', fileUpload);

            await apiPrivate.post('/user/uploadProfilePicture', formDataFile);
            setOriginalPicture(fileURL)
            setFileURL(null);
            setFileUpload(null);
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    // Remove profile picture
    const deleteProfilePicture = async () => {
        try {
            await apiPrivate.delete('/user/deleteProfilePicture');

            setOriginalPicture(null);
            setFileURL(""); 
            setFileUpload(null);
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    // Go back to the home page
    function handleClick() {
        const time = isMobile ? 1500 : 900;
        setIsClosed(true);

        setTimeout(() => {
            setShowSettings(false);
            setIsClosed(false);
        }, time);
        
    }

    // Handle the form change
    function handleChange(event) {
        setError(null);
        const { name, value } = event.target;

        if (name === "password") {
            const error = {};
            if (value !== "") {
                isValidPassword(value, "", error, false);
            }
            setError(error);
        }

        if (name === "phone_number") {
            setFormData((prev) => {
                return {
                    ...prev,
                    profile: {
                        [name]: value
                    }
                }
            })
        } else {
            setFormData((prev) => {
                return {
                    ...prev,
                    [name]: value
                }
            })
        }

    } 

    // Handle the saving of the form
    async function handleSave(event) {
        event.preventDefault();
        loadingDispatch({ type: 'LOAD' });

        const err = {};

        if (formData.first_name !== originalForm.first_name || formData.last_name !== originalForm.last_name) {
            if (formData.first_name === "" || formData.last_name === "") {
                err["name"] = "First name and last name must be filled.";
            } else {
                await changeName();
            }
        } 
        
        if (formData.profile.phone_number !== originalForm.profile.phone_number) {
            if (isValidNumber(formData.profile.phone_number, err)) {
                await changePhoneNumber();
            }
        }

        if (fileUpload) {
            await uploadProfilePicture();
        }
        
        if (formData.old_password === "" && formData.password === "" && formData.confirm_password === "") {
            // all three are not filled, I can ignore this case
            setError(err);
        } else if (formData.old_password === "" || formData.password === "" || formData.confirm_password === "") {
            // if there is at least one that is filled and the others are empty
            err["password"] = "All password fields must be filled.";
        } else if (!isValidPassword(formData.old_password, formData.confirm_password, err, false)) {
            // if the old password doesn't meet the requirement, we're sure it's not the one in the database
            err["old_password"] = "Incorrect password.";
        } else if (isValidPassword(formData.password, formData.confirm_password, err, true)) {
            await changePassword();
        }
        
        setError(err);
        loadingDispatch({ type: 'NO_LOAD' });
    }

    // Handle the logout and reload
    function handleLogout() {
        logout();
        setShowSettings(false);
    }

    // Handle the change of the picture
    async function handleProfileClick(event) {
        const file = event.target.files[0];

        // compress the profile picture if there is one
        const options = {
            maxSizeMB: 0.4,
            maxWidthOrHeight: undefined,
            useWebWorker: true,
        }
        
        const compressedFile = await imageCompression(file, options);
        const finalFile = new File([compressedFile], file.name, {
            type: file.type,
            lastModified: Date.now(),
        });

        setFileUpload(finalFile);

        if (file) setFileURL(URL.createObjectURL(finalFile));
    }

    function handleFileUpload() {
        fileRef.current.click();
    }

    function handleCancelProfile() {
        if (fileRef.current) {
            fileRef.current.value = '';
        }

        setFileURL("");
        setFileUpload(null);
    }

    async function handleRemoveProfile(event) {
        event.preventDefault();
        await deleteProfilePicture();
    }
    
    return (
        <div id="general-settings--container" className={`flex-column${isClosed ? " fade-out" : ""}`}>
            {createPortal(
                <ToastContainer
                    hideProgressBar={false}
                    draggable
                    theme="colored"
                    position="bottom-left"
                />,
                document.body
            )}

            <div id="general-settings" className={isClosed ? "slide-out" : ""}>
                <div id="settings-title">
                    <BackArrow 
                        to={routingMappings[active]}
                        onClick={handleClick}
                        // additionalStyle={{ marginLeft: "-20px" }}
                    />
                    <h1 className="large-bold-title">Account Settings</h1>
                </div>

                <form>
                    <h3 className="settings-subtitle">Name</h3>
                    <div id="name-sign-up--container">
                        <div className="form-row sign-up--text">
                            <label htmlFor="first" className="label">First Name:</label>
                            <input 
                                type="text" 
                                name="first_name" 
                                placeholder="Enter your first name" 
                                className={`sign-up--elements${error && error.name ? " error-borders" : ""}`}
                                onChange={handleChange} 
                                value={formData.first_name} 
                            />
                        </div>
                        <div className="form-row sign-up--text">
                            <label htmlFor="last" className="label">Last Name:</label>
                            <input 
                                type="text" 
                                name="last_name" 
                                placeholder="Enter your last name" 
                                className={`sign-up--elements${error && error.name ? " error-borders" : ""}`} 
                                onChange={handleChange} 
                                value={formData.last_name} 
                            />
                        </div>
                    </div>
                    {error && error.name && <p className="error-p">{error.name}</p>}

                    <h3 className="settings-subtitle">Phone Number</h3>
                    <div id="name-sign-up--container">
                        <div className="form-row sign-up--text" style={{ width: "100%" }}>
                            <label htmlFor="phone_number" className="label">Phone Number:</label>
                            <input 
                                type="tel" 
                                name="phone_number" 
                                placeholder="Enter your phone number" 
                                className={`sign-up--elements${error && error.phone_number ? " error-borders" : ""}`}
                                onChange={handleChange} 
                                value={formData.profile.phone_number} 
                            />
                        </div>
                    </div>
                    {error && error.phone_number && <p className="error-p">{error.phone_number}</p>}
                    
                    <h3 className="settings-subtitle">Profile Picture</h3>
                    <div id="name-sign-up--container">
                        <div className="form-row sign-up--text" style={{ width: "100%" }}>
                        <div className="profile-img-signup--container">
                                {(fileURL || originalPicture) &&
                                    <img
                                        src={fileURL || originalPicture}
                                        alt="Profile Preview"
                                        className="profile-picture profile-img--signup"
                                        onClick={handleFileUpload}
                                        style={{ marginBottom: 0 }}
                                        loading="lazy"
                                    /> 
                                }

                                <ChooseFileButton 
                                    handleProfileClick={handleProfileClick}
                                    fileURL={fileURL}
                                    originalPicture={originalPicture}
                                    fileRef={fileRef}
                                    fileUpload={fileUpload}
                                />

                                {(fileURL || originalPicture) && 
                                    <div className="settings-profile-buttons--container">
                                        <button
                                            className="remove-profile-settings"
                                            onClick={handleRemoveProfile}
                                            name="name"
                                        >
                                            Remove
                                        </button>
                                        {fileURL && 
                                            <button
                                                className="cancel-profile-settings"
                                                onClick={handleCancelProfile}
                                            >
                                                Cancel
                                            </button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <h3 className="settings-subtitle">Password</h3>
                    <div className="form-row">
                        <label htmlFor="password" className="label">Old Password:</label>
                        <input 
                            type="password" 
                            name="old_password" 
                            placeholder="Enter your old password" 
                            className={`sign-up--elements${error && (error.old_password) ? " error-borders" : ""}`} 
                            onChange={handleChange} 
                            value={formData.old_password} 
                        />
                    </div>

                    <div className="form-row">
                        <label htmlFor="password" className="label">New Password:</label>
                        <input 
                            type="password" 
                            name="password" 
                            placeholder="Enter your new password" 
                            className={`sign-up--elements${error && error.password ? " error-borders" : ""}`} 
                            onChange={handleChange} 
                            value={formData.password} 
                        />
                    </div>

                    <div className="form-row">
                        <label htmlFor="password" className="label">Confirm Password:</label>
                        <input 
                            type="password" 
                            name="confirm_password" 
                            placeholder="Confirm your new password" 
                            className={`sign-up--elements${error && error.password ? " error-borders" : ""}`} 
                            onChange={handleChange} 
                            value={formData.confirm_password} 
                        />
                    </div>
                    {error && (error.old_password || error.password) && <p className="error-p">{error.old_password || error.password}</p>}

                    <div id="buttons-settings--container">
                        <div id="save-signout--container">
                            <button className="small-button" onClick={handleSave}>Save Changes</button>
                            <button className="small-button" id="red-button" onClick={handleLogout}>Sign Out</button>
                        </div>
                        {isTutor && <Link 
                            className="small-button" 
                            id="grey-button"
                            to="/advanced-settings"
                            state={{ errorMessage, successMessage, currentRoute }}
                        >Advanced Settings</Link>}
                    </div>
                </form>
            </div>
        </div>
    );
}